import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom'

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

import { Group } from '../../services/pricing/groups';
import MindBodyEnrollmentWidget, { MindBodyEnrollmentWidgetData } from '../../components/widgets/mindbody/MindbodyEnrollmentWidget'
import { Typography } from '@mui/material';

export default function PeakCondition () {
    const isSmallScreen = useScreenSize('sm')
    const params = useParams()
    const menu = params.menuName as Group['identifier'] || 'memberships';

    const widgetId = {
        dataWidgetId: '5e957295e57'
    } as MindBodyEnrollmentWidgetData;

    return (
        <Box
            sx={{ 
                backgroundColor: 'primary.main',
                minHeight: '100vh',
                paddingTop: isSmallScreen ? '20px' : '75px',
                paddingBottom: theme.spacing(isSmallScreen ? 4 : 5)
            }}>
            <Container>
                <Typography 
                    variant='h2'
                    color='secondary.main' 
                    paddingLeft={theme.spacing(isSmallScreen ? 0 : 6)}
                    paddingBottom={theme.spacing(isSmallScreen ? 4 : 5)}
                    maxWidth={isSmallScreen ? '100%' : '75%'}
                    fontWeight={700}>
                    Peak Condition - 12 Week Small Group Personal Training Program
                </Typography>
                <Typography variant='body1' paddingLeft={theme.spacing(isSmallScreen ? 0 : 6)} paddingBottom={theme.spacing(isSmallScreen ? 4 : 5)} color='secondary.main' maxWidth={isSmallScreen ? '100%' : '75%'} sx={{ whiteSpace: 'pre-line' }}>
                    {`Transform Your Body | Enhance Performance | Embrace Adventure

                        Who Is This Program For?
                        Designed specifically for the everyday athlete who wants to maximize performance for adventures this summer or achieve peak conditioning for daily life.

                        Peak Condition is built around proven athletic training principles:
                        - SAID Principle: Specific adaptations for maximum effectiveness.
                        - Overload: Challenging your body strategically for continual progress.
                        - Recovery: Ensuring you recover efficiently to avoid burnout.
                        - Variation: Preventing plateaus and keeping training fresh and engaging.

                        What You'll Do:
                        - Utilize carefully selected compound movements targeting functional, full-body strength.
                        - Condition your body for real-world performance, developing speed, reactive agility, maximal strength, and power endurance for outdoor sports, all while sculpting lean muscle mass.
                        - Follow a smart, strategic progression with clearly defined intensity and volume increases, ensuring continual, measurable progress.

                        Weekly Structure:
                        - 2 In-Person Strength + Conditioning Sessions per week in small groups (up to 6 participants), guided by a certified trainer offering personalized, hands-on coaching.
                        - Sessions: Monday & Wednesday. Times available: 7:00 AM, 8:30 AM, 10:00 AM
                        - 2 Optional Remote Workouts provided weekly for additional progress and flexibility.

                        What's Included:
                        - 12 weeks of structured, progressive training
                        - Expert-led small group coaching
                        - General nutrition guidance to support your performance goals
                        - Supportive, motivating environment to ensure maximum results

                        Investment:
                        $499 (Pay in Full) or $85 every 2 weeks

                        Enroll today and prepare yourself for life's greatest adventures—stronger, faster, and with unmatched confidence.`}
                    </Typography>
                <MindBodyEnrollmentWidget { ...widgetId as MindBodyEnrollmentWidgetData} />
            </Container>

        </Box>
    )
}
import { useEffect } from 'react';

import Header1 from '../../components/text/Header1';
import Box from '../../components/containers/Box';
import Container from '../../components/containers/Container';
import ShopifyBuyButton from '../../components/shopify/ShopifyBuyButton';
import loadShopify from '../../components/shopify/loadShopify';

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';
import { Divider } from '@mui/material';


declare const window: any;

const lifeIsGoatTee = {
    componentId: '1741912104111',
    productId: '9672897528098'
}

const embroideredSweatshirt = {
    componentId: '1741912136910',
    productId: '9652631470370'
}

const giftCard = {
    componentId: '1734041006313',
    productId: '9577301770530'
}

const toteBag = {
    componentId: '1702328335436',
    productId: '8392964276514'
}

const joinTheHerdSticker = {
    componentId: '1702502263498',
    productId: '8824469356834'
}

const logoSticker = {
    componentId: '1702502439669',
    productId: '8824462049570'
}

export default function Shop () {
    const isSmallScreen = useScreenSize('sm');

    useEffect(() => {
        loadShopify(() => {
            if (window.ShopifyBuy) {
                if (window.ShopifyBuy.UI) {
                    ShopifyBuyInit(lifeIsGoatTee.componentId, lifeIsGoatTee.productId);
                    ShopifyBuyInit(embroideredSweatshirt.componentId, embroideredSweatshirt.productId);
                    ShopifyBuyInit(giftCard.componentId, giftCard.productId);
                    ShopifyBuyInit(toteBag.componentId, toteBag.productId);
                    ShopifyBuyInit(joinTheHerdSticker.componentId, joinTheHerdSticker.productId);
                    ShopifyBuyInit(logoSticker.componentId, logoSticker.productId);
                }
            }
        })
    })

    return (  
        <Box 
            styles={{
                backgroundColor: 'secondary.main',
                padding: '25px 25px'
            }}>
            <Container>
                <Header1 
                    textAlign='center'
                    color='primary.main'
                    styles={{
                        marginTop: theme.spacing(isSmallScreen ? 4 : 5),
                        marginBottom: theme.spacing(isSmallScreen ? 4 : 5)
                    }}>
                    Our Merchandise
                </Header1>

                <Divider variant="middle" sx={{ bgcolor: "primary.main" }}/>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}`, paddingTop: `${theme.spacing(isSmallScreen ? 4 : 5)}`}}>
                    <ShopifyBuyButton componentId = {lifeIsGoatTee.componentId} />
                </Box>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}` }}>
                    <ShopifyBuyButton componentId = {embroideredSweatshirt.componentId} />
                </Box>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}` }}>
                    <ShopifyBuyButton componentId = {giftCard.componentId} />
                </Box>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}` }}>
                    <ShopifyBuyButton componentId = {toteBag.componentId} />
                </Box>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}` }}>
                    <ShopifyBuyButton componentId = {joinTheHerdSticker.componentId} />
                </Box>

                <Box styles={{ paddingBottom: `${theme.spacing(isSmallScreen ? 3 : 4)}` }}>
                    <ShopifyBuyButton componentId = {logoSticker.componentId} />
                </Box>
            </Container>
        </Box>
    )
}

function ShopifyBuyInit(componentId: string, productId: string) {
    
    var client = window.ShopifyBuy.buildClient({
        domain: 'goathousegym.myshopify.com',
        storefrontAccessToken: '58f2cc7e51d8fb30aa93bebb513f374b',
    });

    window.ShopifyBuy.UI.onReady(client).then(function (ui: any) {
        ui.createComponent('product', {
            id: `${productId}`,
            node: document.getElementById(`product-component-${componentId}`),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
                "product": {
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0",
                        "margin-bottom": "50px"
                        },
                        "text-align": "left"
                    },
                    "title": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "26px",
                        "color": "#1C1C1C"
                    },
                    "button": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "18px",
                        "padding-top": "17px",
                        "padding-bottom": "17px",
                        "color": "#000000",
                        ":hover": {
                        "color": "#000000",
                        "background-color": "#bbc83f"
                        },
                        "background-color": "#d0de46",
                        ":focus": {
                        "background-color": "#bbc83f"
                        },
                        "border-radius": "6px",
                        "padding-left": "50px",
                        "padding-right": "50px"
                    },
                    "quantityInput": {
                        "font-size": "18px",
                        "padding-top": "17px",
                        "padding-bottom": "17px"
                    },
                    "price": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "18px",
                        "color": "#1C1C1C"
                    },
                    "compareAt": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "15.299999999999999px",
                        "color": "#1C1C1C"
                    },
                    "unitPrice": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "15.299999999999999px",
                        "color": "#1C1C1C"
                    },
                    "description": {
                        "font-family": "Lato, sans-serif",
                        "color": "#1C1C1C"
                    }
                    },
                    "layout": "horizontal",
                    "contents": {
                    "img": false,
                    "imgWithCarousel": true,
                    "button": false,
                    "buttonWithQuantity": true,
                    "description": true
                    },
                    "width": "100%",
                    "text": {
                    "button": "Add to cart"
                    },
                    "googleFonts": [
                    "Lato"
                    ]
                },
                "productSet": {
                    "styles": {
                    "products": {
                        "@media (min-width: 601px)": {
                        "margin-left": "-20px"
                        }
                    }
                    }
                },
                "modalProduct": {
                    "contents": {
                    "img": false,
                    "imgWithCarousel": true,
                    "button": false,
                    "buttonWithQuantity": true
                    },
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px"
                        }
                    },
                    "button": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "18px",
                        "padding-top": "17px",
                        "padding-bottom": "17px",
                        "color": "#000000",
                        ":hover": {
                        "color": "#000000",
                        "background-color": "#bbc83f"
                        },
                        "background-color": "#d0de46",
                        ":focus": {
                        "background-color": "#bbc83f"
                        },
                        "border-radius": "6px",
                        "padding-left": "50px",
                        "padding-right": "50px"
                    },
                    "quantityInput": {
                        "font-size": "18px",
                        "padding-top": "17px",
                        "padding-bottom": "17px"
                    },
                    "title": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "bold",
                        "font-size": "26px",
                        "color": "#4c4c4c"
                    },
                    "price": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "normal",
                        "font-size": "18px",
                        "color": "#4c4c4c"
                    },
                    "compareAt": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "normal",
                        "font-size": "15.299999999999999px",
                        "color": "#4c4c4c"
                    },
                    "unitPrice": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "normal",
                        "font-size": "15.299999999999999px",
                        "color": "#4c4c4c"
                    },
                    "description": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "normal",
                        "font-size": "14px",
                        "color": "#4c4c4c"
                    }
                    },
                    "googleFonts": [
                    "Lato"
                    ],
                    "text": {
                    "button": "Add to cart"
                    }
                },
                "option": {},
                "cart": {
                    "styles": {
                    "button": {
                        "font-family": "Lato, sans-serif",
                        "font-size": "18px",
                        "padding-top": "17px",
                        "padding-bottom": "17px",
                        "color": "#000000",
                        ":hover": {
                        "color": "#000000",
                        "background-color": "#bbc83f"
                        },
                        "background-color": "#d0de46",
                        ":focus": {
                        "background-color": "#bbc83f"
                        },
                        "border-radius": "6px"
                    }
                    },
                    "text": {
                    "total": "Subtotal",
                    "button": "Checkout"
                    },
                    "googleFonts": [
                    "Lato"
                    ]
                },
                "toggle": {
                    "styles": {
                    "toggle": {
                        "font-family": "Lato, sans-serif",
                        "background-color": "#d0de46",
                        ":hover": {
                        "background-color": "#bbc83f"
                        },
                        ":focus": {
                        "background-color": "#bbc83f"
                        }
                    },
                    "count": {
                        "font-size": "18px",
                        "color": "#000000",
                        ":hover": {
                        "color": "#000000"
                        }
                    },
                    "iconPath": {
                        "fill": "#000000"
                    }
                    },
                    "googleFonts": [
                    "Lato"
                    ]
                }
            },
        });
    });
}
